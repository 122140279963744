<template>
  <q-table flat bordered :data="creative" row-key="id" :pagination="pagination" grid>
    <template v-slot:top-right>
      <q-checkbox
        label="Selecionar Todos"
        v-model="areAllCreativesSelected"
        @input="(val) => (selection = val ? creative.map((val) => val.id) : [])"
      />

      <q-dialog v-model="selectionDialog">
        <q-card class="q-pa-md flex">
          <q-card-section class="">
            Todos os <b>{{ selection.length }}</b> criativos selecionados serão<b>
              {{ selectedStatus == 2 ? 'excluídos' : 'desativados' }}</b
            >. Tem certeza que deseja prosseguir?
          </q-card-section>

          <q-card-actions class="flex justify-center q-gutter-md" style="width: 100%">
            <q-btn label="Cancelar" unelevated color="negative" @click="selectionDialog = false" />
            <q-btn label="Confirmar" unelevated color="positive" @click="confirmSelected(selectedStatus)" />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </template>
    <template v-slot:top-left>
      <transition name="fade">
        <div class="" v-if="selection.length">
          <q-btn label="Desativar selecionados" class="q-mr-md" unelevated color="warning" @click="treatSelected(3)" />
          <q-btn label="Excluir selecionados" unelevated color="negative" @click="treatSelected(2)" />
        </div>
      </transition>
    </template>
    <template v-slot:item="props">
      <div class="q-pa-sm col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <q-card flat bordered class="q-pa-sm">
          <q-img
            :src="props.row.image_campaign_url"
            height="200px"
            contain
            v-if="['coupon', 'hyperlink', 'deal'].includes(props.row.type_name) && props.row.image_campaign_url"
          >
            <template v-slot:error>
              <div class="absolute-full flex flex-center bg-negative text-white">Cannot load image</div>
            </template>
          </q-img>
          <q-img
            :src="props.row.image_url"
            height="200px"
            contain
            v-else-if="props.row.type_name == 'image' && props.row.image_url"
          >
            <template v-slot:error>
              <div class="absolute-full flex flex-center bg-negative text-white">Cannot load image</div>
            </template>
          </q-img>
          <q-avatar
            v-else
            class="full-width"
            style="height: 200px"
            font-size="100px"
            :color="!$q.dark.isActive ? 'grey-3' : 'dark'"
            :text-color="!$q.dark.isActive ? 'grey-5' : 'white'"
            :icon="getIconType(props.row.type_name)"
          />
          <q-card-section class="q-pa-none">
            <q-list dense>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('ID') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-weight-bold text-positive">{{ props.row.id }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('campaign') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.campaign_name }} ({{ props.row.id_campaign }})</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">Status:</q-item-label>
                </q-item-section>
                <q-item-section class="text-weight-bold">
                  <q-item-label v-if="props.row.status == 2" class="text-success">Pendente</q-item-label>
                  <q-item-label v-if="props.row.status == 1" class="text-primary">{{ $t('active') }}</q-item-label>
                  <q-item-label v-if="props.row.status == 3" class="text-negative">{{ $t('disabled') }}</q-item-label>
                  <q-item-label v-if="props.row.status == 0" class="text-primary">{{ $t('scheduled') }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-if="
                  props.row.type_name == 'coupon' || props.row.type_name == 'deal' || props.row.type_name == 'hyperlink'
                "
              >
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('title') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="limited-text"
                    >{{ props.row.title
                    }}<q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">{{
                      props.row.title
                    }}</q-tooltip></q-item-label
                  >
                </q-item-section>
              </q-item>
              <q-item
                v-if="
                  props.row.type_name == 'coupon' || props.row.type_name == 'deal' || props.row.type_name == 'hyperlink'
                "
              >
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('description') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="limited-text"
                    >{{ props.row.description
                    }}<q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">{{
                      props.row.description
                    }}</q-tooltip></q-item-label
                  >
                </q-item-section>
              </q-item>
              <q-item v-if="props.row.type_name == 'emailmkt'">
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('subject') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="limited-text"
                    >{{ props.row.subject
                    }}<q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">{{
                      props.row.subject
                    }}</q-tooltip></q-item-label
                  >
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('type') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.type_description }}</q-item-label>
                </q-item-section>
              </q-item>

              <q-item v-if="props.row.type_name == 'coupon'">
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('code') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.code }}</q-item-label>
                </q-item-section>
              </q-item>

              <q-item v-if="props.row.type_name == 'image'">
                <q-item-section>
                  <q-item-label>{{ $t('size') }}</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.size }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('activation_date') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label v-if="props.row.activation_date">{{
                    $moment(props.row.activation_date).locale($t('format_date')).format('L')
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('activation_time') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label v-if="props.row.activation_date">{{
                    $moment(props.row.activation_date).locale($t('format_date')).format('LT')
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('expiration_date') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label v-if="props.row.expiration_date">{{
                    $moment(props.row.expiration_date).locale($t('format_date')).format('L')
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('expiration_time') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label v-if="props.row.expiration_date">{{
                    $moment(props.row.expiration_date).locale($t('format_date')).format('LT')
                  }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('last_update') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label v-if="props.row.updated_at">{{
                    $moment(props.row.updated_at).locale($t('format_date')).format('L')
                  }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
          <q-card-section class="flex justify-center">
            <q-btn
              color="positive"
              no-caps
              :label="$t('generate_tag')"
              class="full-width q-mb-sm"
              @click="openGenerateTag(props.row)"
              unelevated
            />
            <q-btn
              color="dark"
              :label="$t('edit')"
              class="full-width q-mb-sm"
              no-caps
              outline
              @click="editCreative(props.row)"
            />
            <!-- :to="'/creative/manager/' + props.row.id" -->

            <q-checkbox :val="props.row.id" v-model="selection" />
          </q-card-section>
        </q-card>
      </div>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <q-btn
          unelevated
          round
          flat
          icon="mdi-pencil-outline"
          color="primary"
          :to="'/creative/manager/' + props.row.id"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Editar </q-tooltip>
        </q-btn>
        <q-btn
          unelevated
          round
          flat
          icon="mdi-account-off-outline"
          color="primary"
          @click="ActionSetDialogUser({ value: props.row })"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Desativar </q-tooltip>
        </q-btn>
        <q-btn
          unelevated
          round
          flat
          icon="mdi-trash-can-outline"
          color="negative"
          @click="ActionSetDialogUser({ value: props.row })"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Excluir </q-tooltip>
        </q-btn>
      </q-td>
    </template>
    <template v-slot:bottom>
      <!--  <pagination-table
        @change="getCreative"
        :data="reqPagination"
        :pagination="pagination"
      /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>
<script>
import CreativeMixin from '../../mixins/CreativeMixin';
import CreativeService from '@/services/CreativesService';
//import PaginationTable from "../../widgets/PaginationTable.vue";
export default {
  //components: { PaginationTable },
  name: 'ListCreative',
  mixins: [CreativeMixin, CreativeService],
  data() {
    return {
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 12
      },
      selection: [],
      areAllCreativesSelected: false,
      selectionDialog: false,
      selectedStatus: null
    };
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getCreative(`page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
        this.selection = [];
        this.areAllCreativesSelected = false;
      }
    },

    selection() {
      if (this.selection.length > 0) {
        this.areAllCreativesSelected =
          this.selection.reduce((acc, curr) => acc + curr) ==
          this.creative.map((val) => val.id).reduce((acc, curr) => acc + curr);
      }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  methods: {
    editCreative(creative) {
      this.ActionSetCreativeSelect(creative);
      this.$router.push({ path: `/creative/manager/${creative.id}` });
    },

    openGenerateTag(creative) {
      this.ActionSetCreativeSelect(creative);
      this.$router.push({ path: `/creative/tag/${creative.id}` });
    },

    getIconType(type) {
      switch (type) {
        case 'coupon':
          return 'fas fa-ticket-alt';
        case 'emailmkt':
          return 'fas fa-envelope';
        case 'deal':
          return 'fas fa-percentage';
        case 'hyperlink':
          return 'fas fa-file-alt';
        default:
          return 'fas fa-envelope';
      }
    },

    async treatSelected(status) {
      this.selectedStatus = status;
      this.selectionDialog = true;
    },

    async confirmSelected() {
      try {
        this.onLoading(true);

        const { data } = await this.setStatusCreativeLot(this.selectedStatus, this.selection);
        this.successNotify(data.message);
        this.getCreative(`page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
        this.selection = [];
        this.areAllCreativesSelected = false;
        this.selectionDialog = false;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.getCreative(null, '%26perPage=' + this.pagination.rowsPerPage);
  }
};
</script>

<style scoped>
.limited-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
